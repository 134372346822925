// src/mixins/commonAuthMixin.js
import isEmail from 'validator/lib/isEmail';

export default {
  data() {
    return {
      email: '',
      password: '',
      isEmailValid: undefined,
      isPasswordValid: undefined,
      showAlert: false,
      apiErrorMessage: '',
      isLoading: false,
      recaptchaToken: null,
      recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_KEY,
      widgetId: null,
      isSubmitting: false,
      isPasswordVisible: false,
    };
  },
  methods: {
    handleClickOutside(event) {
      if (!event.target.closest('.login-form') && !this.isSubmitting) {
        this.isLoading = false;
      }
    },
    loadRecaptchaScript() {
      if (window.grecaptcha) {
        this.renderRecaptcha();
      } else {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit`;
        script.async = true;
        script.defer = true;
        script.onerror = () => {
          console.error('Error loading reCAPTCHA script');
          this.showAlert = true;
          this.apiErrorMessage = 'Error loading reCAPTCHA. Try again later.';
        };
        document.head.appendChild(script);
        // Expose the callback globally so that reCAPTCHA can call it
        window.onRecaptchaLoad = this.renderRecaptcha;
      }
    },
    renderRecaptcha() {
      if (window.grecaptcha) {
        this.widgetId = window.grecaptcha.render('recaptcha-container', {
          sitekey: this.recaptchaSiteKey,
          size: 'invisible',
          callback: this.onRecaptchaSuccess,
          'error-callback': this.onRecaptchaError,
        });
      }
    },
    executeRecaptcha() {
        if (window.grecaptcha && this.widgetId !== null) {
            this.isLoading = true;
            window.grecaptcha.execute(this.widgetId);
            } else {
            this.showAlert = true;
            this.apiErrorMessage = "reCAPTCHA is not ready. Please try again later.";
            }
    },
    onRecaptchaSuccess(token) {
      this.recaptchaToken = token;
      // Delegate to the component-specific success handler
      this.handleRecaptchaSuccess && this.handleRecaptchaSuccess();
    },
    onRecaptchaError() {
      this.showAlert = true;
      this.apiErrorMessage = "reCAPTCHA error occurred. Please try again.";
      this.isLoading = false;
    },
    handleGoogleLogin(response) {
      if (response.credential) {
        this.idToken = response.credential;
        // Delegate to the component-specific Google login handler
        this.handleGoogleLoginApi && this.handleGoogleLoginApi('google');
      }
    },
    validateEmail() {
      this.isEmailValid = isEmail(this.email);
      return this.isEmailValid;
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    closeAlert() {
      this.showAlert = false;
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    this.loadRecaptchaScript();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
