import axios from 'axios'
import { mapState } from 'vuex'
import { removeToken } from '@/helpers/authHelpers';

export default {
  props: [],
  computed: {
    ...mapState(['selectedApiService', 'selectedUserApiResource', 'selectedPage', 'selectedUiComponent', 'selectedUiComponentId'])
  },
  methods: {
    handleLogout() {
      axios.post('/logout.php')
        .then(() => {
        
          this.$store?.commit('setSelectedApiService', null);
          this.$store?.commit('setSelectedUserApiResource', null);
          this.$store?.commit('setSelectedPage', null);
          this.$store?.commit('setSelectedUiComponent', null);
          this.$store?.commit('setSelectedUiComponentId', null);

          removeToken();

          window.location.reload(); // Force a page refresh to clear the cache

          // this.$router?.push({ name: 'Login' });

          // this.$router.go(); // Clear the router history

        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
