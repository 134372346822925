export const isDevelopment =
  process.env.NODE_ENV === "production" ? false : true;

export const uiComponents = [
  {
    name: "Data table",
    type: "data-table",
    icon: "icon-data-table",
  },
  {
    name: "Form",
    type: "form-item",
    icon: "icon-form",
  },
  {
    name: "Single item view",
    type: "view-entry",
    icon: "icon-single-item-view",
  },
];

export const apiProviders = [
  { alias: "woocommerce", name: "WooCommerce"},
  { alias: "twilio", name: "Twilio" },
  { alias: "merge-hris", name: "Merge HRIS" },
  { alias: "merge-ats", name: "Merge ATS"},
];

export const dateFormats = [
  { format: "dd.MM.yyyy", example: "19.08.2025" },
  { format: "dd/MM/yyyy", example: "19/08/2025" },
  { format: "dd-MM-yyyy", example: "19-08-2025" },
  { format: "M/d/yyyy", example: "8/19/2025" },
  { format: "M/d/yy", example: "8/19/25" },
  { format: "d/M/yy", example: "19/8/25" },
  { format: "yyyy/M/d", example: "2025/8/19" },
  { format: "yyyy.MM.dd", example: "2025.08.19" },
  { format: "yyyy-MM-dd", example: "2025-08-19" },

];

export const timeFormats = [
  { format: "HH:mm", example: "14:30" },
  { format: "hh:mm a", example: "2:30 PM" }
];

export const decimalSeparators = [
  { symbol: ".", label: "Period (.) • 1234.56" },
  { symbol: ",", label: "Comma (,) • 1234,56" }
];

export const currencies = [
  {
      code: "AFN",
      name: "Afghan afghani",
      symbol: "؋",
      symbol_html: "&#x60b;"
  },
  {
      code: "ALL",
      name: "Albanian lek",
      symbol: "L",
      symbol_html: "L"
  },
  {
      code: "DZD",
      name: "Algerian dinar",
      symbol: "د.ج",
      symbol_html: "&#x62f;.&#x62c;"
  },
  {
      code: "AOA",
      name: "Angolan kwanza",
      symbol: "Kz",
      symbol_html: "Kz"
  },
  {
      code: "ARS",
      name: "Argentine peso",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "AMD",
      name: "Armenian dram",
      symbol: "AMD",
      symbol_html: "AMD"
  },
  {
      code: "AWG",
      name: "Aruban florin",
      symbol: "Afl.",
      symbol_html: "Afl."
  },
  {
      code: "AUD",
      name: "Australian dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "AZN",
      name: "Azerbaijani manat",
      symbol: "₼",
      symbol_html: "&#8380;"
  },
  {
      code: "BSD",
      name: "Bahamian dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "BHD",
      name: "Bahraini dinar",
      symbol: ".د.ب",
      symbol_html: ".&#x62f;.&#x628;"
  },
  {
      code: "BDT",
      name: "Bangladeshi taka",
      symbol: "৳",
      symbol_html: "&#2547;&nbsp;"
  },
  {
      code: "BBD",
      name: "Barbadian dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "BYN",
      name: "Belarusian ruble",
      symbol: "Br",
      symbol_html: "Br"
  },
  {
      code: "BYR",
      name: "Belarusian ruble (old)",
      symbol: "Br",
      symbol_html: "Br"
  },
  {
      code: "BZD",
      name: "Belize dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "BMD",
      name: "Bermudian dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "BTN",
      name: "Bhutanese ngultrum",
      symbol: "Nu.",
      symbol_html: "Nu."
  },
  {
      code: "BTC",
      name: "Bitcoin",
      symbol: "₿",
      symbol_html: "&#3647;"
  },
  {
      code: "BOB",
      name: "Bolivian boliviano",
      symbol: "Bs.",
      symbol_html: "Bs."
  },
  {
      code: "BAM",
      name: "Bosnia and Herzegovina convertible mark",
      symbol: "KM",
      symbol_html: "KM"
  },
  {
      code: "BWP",
      name: "Botswana pula",
      symbol: "P",
      symbol_html: "P"
  },
  {
      code: "BRL",
      name: "Brazilian real",
      symbol: "R$",
      symbol_html: "&#82;&#36;"
  },
  {
      code: "BND",
      name: "Brunei dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "BGN",
      name: "Bulgarian lev",
      symbol: "лв.",
      symbol_html: "&#1083;&#1074;."
  },
  {
      code: "MMK",
      name: "Burmese kyat",
      symbol: "Ks",
      symbol_html: "Ks"
  },
  {
      code: "BIF",
      name: "Burundian franc",
      symbol: "Fr",
      symbol_html: "Fr"
  },
  {
      code: "KHR",
      name: "Cambodian riel",
      symbol: "៛",
      symbol_html: "&#x17db;"
  },
  {
      code: "CAD",
      name: "Canadian dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "CVE",
      name: "Cape Verdean escudo",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "KYD",
      name: "Cayman Islands dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "XAF",
      name: "Central African CFA franc",
      symbol: "CFA",
      symbol_html: "CFA"
  },
  {
      code: "XPF",
      name: "CFP franc",
      symbol: "Fr",
      symbol_html: "Fr"
  },
  {
      code: "CLP",
      name: "Chilean peso",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "CNY",
      name: "Chinese yuan",
      symbol: "¥",
      symbol_html: "&yen;"
  },
  {
      code: "COP",
      name: "Colombian peso",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "KMF",
      name: "Comorian franc",
      symbol: "Fr",
      symbol_html: "Fr"
  },
  {
      code: "CDF",
      name: "Congolese franc",
      symbol: "Fr",
      symbol_html: "Fr"
  },
  {
      code: "CRC",
      name: "Costa Rican colón",
      symbol: "₡",
      symbol_html: "&#x20a1;"
  },
  {
      code: "HRK",
      name: "Croatian kuna",
      symbol: "kn",
      symbol_html: "kn"
  },
  {
      code: "CUC",
      name: "Cuban convertible peso",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "CUP",
      name: "Cuban peso",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "CZK",
      name: "Czech koruna",
      symbol: "Kč",
      symbol_html: "&#75;&#269;"
  },
  {
      code: "DKK",
      name: "Danish krone",
      symbol: "kr.",
      symbol_html: "kr."
  },
  {
      code: "DJF",
      name: "Djiboutian franc",
      symbol: "Fr",
      symbol_html: "Fr"
  },
  {
      code: "DOP",
      name: "Dominican peso",
      symbol: "RD$",
      symbol_html: "RD&#36;"
  },
  {
      code: "XCD",
      name: "East Caribbean dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "EGP",
      name: "Egyptian pound",
      symbol: "EGP",
      symbol_html: "EGP"
  },
  {
      code: "ERN",
      name: "Eritrean nakfa",
      symbol: "Nfk",
      symbol_html: "Nfk"
  },
  {
      code: "ETB",
      name: "Ethiopian birr",
      symbol: "Br",
      symbol_html: "Br"
  },
  {
      code: "EUR",
      name: "Euro",
      symbol: "€",
      symbol_html: "&euro;"
  },
  {
      code: "FKP",
      name: "Falkland Islands pound",
      symbol: "£",
      symbol_html: "&pound;"
  },
  {
      code: "FJD",
      name: "Fijian dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "GMD",
      name: "Gambian dalasi",
      symbol: "D",
      symbol_html: "D"
  },
  {
      code: "GEL",
      name: "Georgian lari",
      symbol: "₾",
      symbol_html: "&#x20be;"
  },
  {
      code: "GHS",
      name: "Ghana cedi",
      symbol: "₵",
      symbol_html: "&#x20b5;"
  },
  {
      code: "GIP",
      name: "Gibraltar pound",
      symbol: "£",
      symbol_html: "&pound;"
  },
  {
      code: "GTQ",
      name: "Guatemalan quetzal",
      symbol: "Q",
      symbol_html: "Q"
  },
  {
      code: "GGP",
      name: "Guernsey pound",
      symbol: "£",
      symbol_html: "&pound;"
  },
  {
      code: "GNF",
      name: "Guinean franc",
      symbol: "Fr",
      symbol_html: "Fr"
  },
  {
      code: "GYD",
      name: "Guyanese dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "HTG",
      name: "Haitian gourde",
      symbol: "G",
      symbol_html: "G"
  },
  {
      code: "HNL",
      name: "Honduran lempira",
      symbol: "L",
      symbol_html: "L"
  },
  {
      code: "HKD",
      name: "Hong Kong dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "HUF",
      name: "Hungarian forint",
      symbol: "Ft",
      symbol_html: "&#70;&#116;"
  },
  {
      code: "ISK",
      name: "Icelandic króna",
      symbol: "kr.",
      symbol_html: "kr."
  },
  {
      code: "INR",
      name: "Indian rupee",
      symbol: "₹",
      symbol_html: "&#8377;"
  },
  {
      code: "IDR",
      name: "Indonesian rupiah",
      symbol: "Rp",
      symbol_html: "Rp"
  },
  {
      code: "IRR",
      name: "Iranian rial",
      symbol: "﷼",
      symbol_html: "&#xfdfc;"
  },
  {
      code: "IRT",
      name: "Iranian toman",
      symbol: "تومان",
      symbol_html: "&#x062A;&#x0648;&#x0645;&#x0627;&#x0646;"
  },
  {
      code: "IQD",
      name: "Iraqi dinar",
      symbol: "د.ع",
      symbol_html: "&#x62f;.&#x639;"
  },
  {
      code: "ILS",
      name: "Israeli new shekel",
      symbol: "₪",
      symbol_html: "&#8362;"
  },
  {
      code: "JMD",
      name: "Jamaican dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "JPY",
      name: "Japanese yen",
      symbol: "¥",
      symbol_html: "&yen;"
  },
  {
      code: "JEP",
      name: "Jersey pound",
      symbol: "£",
      symbol_html: "&pound;"
  },
  {
      code: "JOD",
      name: "Jordanian dinar",
      symbol: "د.ا",
      symbol_html: "&#x62f;.&#x627;"
  },
  {
      code: "KZT",
      name: "Kazakhstani tenge",
      symbol: "₸",
      symbol_html: "&#8376;"
  },
  {
      code: "KES",
      name: "Kenyan shilling",
      symbol: "KSh",
      symbol_html: "KSh"
  },
  {
      code: "KWD",
      name: "Kuwaiti dinar",
      symbol: "د.ك",
      symbol_html: "&#x62f;.&#x643;"
  },
  {
      code: "KGS",
      name: "Kyrgyzstani som",
      symbol: "сом",
      symbol_html: "&#x441;&#x43e;&#x43c;"
  },
  {
      code: "LAK",
      name: "Lao kip",
      symbol: "₭",
      symbol_html: "&#8365;"
  },
  {
      code: "LBP",
      name: "Lebanese pound",
      symbol: "ل.ل",
      symbol_html: "&#x644;.&#x644;"
  },
  {
      code: "LSL",
      name: "Lesotho loti",
      symbol: "L",
      symbol_html: "L"
  },
  {
      code: "LRD",
      name: "Liberian dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "LYD",
      name: "Libyan dinar",
      symbol: "د.ل",
      symbol_html: "&#x62f;.&#x644;"
  },
  {
      code: "MOP",
      name: "Macanese pataca",
      symbol: "P",
      symbol_html: "P"
  },
  {
      code: "MKD",
      name: "Macedonian denar",
      symbol: "ден",
      symbol_html: "&#x434;&#x435;&#x43d;"
  },
  {
      code: "MGA",
      name: "Malagasy ariary",
      symbol: "Ar",
      symbol_html: "Ar"
  },
  {
      code: "MWK",
      name: "Malawian kwacha",
      symbol: "MK",
      symbol_html: "MK"
  },
  {
      code: "MYR",
      name: "Malaysian ringgit",
      symbol: "RM",
      symbol_html: "&#82;&#77;"
  },
  {
      code: "MVR",
      name: "Maldivian rufiyaa",
      symbol: "ރ.",
      symbol_html: ".&#x783;"
  },
  {
      code: "IMP",
      name: "Manx pound",
      symbol: "£",
      symbol_html: "&pound;"
  },
  {
      code: "MRU",
      name: "Mauritanian ouguiya",
      symbol: "UM",
      symbol_html: "UM"
  },
  {
      code: "MUR",
      name: "Mauritian rupee",
      symbol: "₨",
      symbol_html: "&#x20a8;"
  },
  {
      code: "MXN",
      name: "Mexican peso",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "MDL",
      name: "Moldovan leu",
      symbol: "MDL",
      symbol_html: "MDL"
  },
  {
      code: "MNT",
      name: "Mongolian tögrög",
      symbol: "₮",
      symbol_html: "&#x20ae;"
  },
  {
      code: "MAD",
      name: "Moroccan dirham",
      symbol: "د.م.",
      symbol_html: "&#x62f;.&#x645;."
  },
  {
      code: "MZN",
      name: "Mozambican metical",
      symbol: "MT",
      symbol_html: "MT"
  },
  {
      code: "NAD",
      name: "Namibian dollar",
      symbol: "N$",
      symbol_html: "N&#36;"
  },
  {
      code: "NPR",
      name: "Nepalese rupee",
      symbol: "₨",
      symbol_html: "&#8360;"
  },
  {
      code: "ANG",
      name: "Netherlands Antillean guilder",
      symbol: "ƒ",
      symbol_html: "&fnof;"
  },
  {
      code: "TWD",
      name: "New Taiwan dollar",
      symbol: "NT$",
      symbol_html: "&#78;&#84;&#36;"
  },
  {
      code: "NZD",
      name: "New Zealand dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "NIO",
      name: "Nicaraguan córdoba",
      symbol: "C$",
      symbol_html: "C&#36;"
  },
  {
      code: "NGN",
      name: "Nigerian naira",
      symbol: "₦",
      symbol_html: "&#8358;"
  },
  {
      code: "KPW",
      name: "North Korean won",
      symbol: "₩",
      symbol_html: "&#x20a9;"
  },
  {
      code: "NOK",
      name: "Norwegian krone",
      symbol: "kr",
      symbol_html: "&#107;&#114;"
  },
  {
      code: "OMR",
      name: "Omani rial",
      symbol: "ر.ع.",
      symbol_html: "&#x631;.&#x639;."
  },
  {
      code: "PKR",
      name: "Pakistani rupee",
      symbol: "₨",
      symbol_html: "&#8360;"
  },
  {
      code: "PAB",
      name: "Panamanian balboa",
      symbol: "B/.",
      symbol_html: "฿"
  },
  {
      code: "PGK",
      name: "Papua New Guinean kina",
      symbol: "K",
      symbol_html: "K"
  },
  {
      code: "PYG",
      name: "Paraguayan guaraní",
      symbol: "₲",
      symbol_html: "&#8370;"
  },
  {
      code: "PEN",
      name: "Peruvian sol",
      symbol: "S/",
      symbol_html: "S/"
  },
  {
      code: "PHP",
      name: "Philippine peso",
      symbol: "₱",
      symbol_html: "&#8369;"
  },
  {
      code: "PLN",
      name: "Polish złoty",
      symbol: "zł",
      symbol_html: "&#122;&#322;"
  },
  {
      code: "GBP",
      name: "Pound sterling",
      symbol: "£",
      symbol_html: "&pound;"
  },
  {
      code: "QAR",
      name: "Qatari riyal",
      symbol: "ر.ق",
      symbol_html: "&#x631;.&#x642;"
  },
  {
      code: "RON",
      name: "Romanian leu",
      symbol: "lei",
      symbol_html: "lei"
  },
  {
      code: "RUB",
      name: "Russian ruble",
      symbol: "₽",
      symbol_html: "&#8381;"
  },
  {
      code: "RWF",
      name: "Rwandan franc",
      symbol: "Fr",
      symbol_html: "Fr"
  },
  {
      code: "SHP",
      name: "Saint Helena pound",
      symbol: "£",
      symbol_html: "&pound;"
  },
  {
      code: "WST",
      name: "Samoan tālā",
      symbol: "T",
      symbol_html: "T"
  },
  {
      code: "STN",
      name: "São Tomé and Príncipe dobra",
      symbol: "Db",
      symbol_html: "Db"
  },
  {
      code: "SAR",
      name: "Saudi riyal",
      symbol: "ر.س",
      symbol_html: "&#x631;.&#x633;"
  },
  {
      code: "RSD",
      name: "Serbian dinar",
      symbol: "рсд",
      symbol_html: "&#1088;&#1089;&#1076;"
  },
  {
      code: "SCR",
      name: "Seychellois rupee",
      symbol: "₨",
      symbol_html: "&#x20a8;"
  },
  {
      code: "SLL",
      name: "Sierra Leonean leone",
      symbol: "Le",
      symbol_html: "Le"
  },
  {
      code: "SGD",
      name: "Singapore dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "SBD",
      name: "Solomon Islands dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "SOS",
      name: "Somali shilling",
      symbol: "Sh",
      symbol_html: "Sh"
  },
  {
      code: "ZAR",
      name: "South African rand",
      symbol: "R",
      symbol_html: "&#82;"
  },
  {
      code: "KRW",
      name: "South Korean won",
      symbol: "₩",
      symbol_html: "&#8361;"
  },
  {
      code: "SSP",
      name: "South Sudanese pound",
      symbol: "£",
      symbol_html: "&pound;"
  },
  {
      code: "LKR",
      name: "Sri Lankan rupee",
      symbol: "රු",
      symbol_html: "&#xdbb;&#xdd4;"
  },
  {
      code: "SDG",
      name: "Sudanese pound",
      symbol: "ج.س.",
      symbol_html: "&#x62c;.&#x633;."
  },
  {
      code: "SRD",
      name: "Surinamese dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "SZL",
      name: "Swazi lilangeni",
      symbol: "E",
      symbol_html: "E"
  },
  {
      code: "SEK",
      name: "Swedish krona",
      symbol: "kr",
      symbol_html: "&#107;&#114;"
  },
  {
      code: "CHF",
      name: "Swiss franc",
      symbol: "CHF",
      symbol_html: "&#67;&#72;&#70;"
  },
  {
      code: "SYP",
      name: "Syrian pound",
      symbol: "ل.س",
      symbol_html: "&#x644;.&#x633;"
  },
  {
      code: "TJS",
      name: "Tajikistani somoni",
      symbol: "ЅМ",
      symbol_html: "&#x405;&#x41c;"
  },
  {
      code: "TZS",
      name: "Tanzanian shilling",
      symbol: "Sh",
      symbol_html: "Sh"
  },
  {
      code: "THB",
      name: "Thai baht",
      symbol: "฿",
      symbol_html: "&#3647;"
  },
  {
      code: "TOP",
      name: "Tongan paʻanga",
      symbol: "T$",
      symbol_html: "T&#36;"
  },
  {
      code: "PRB",
      name: "Transnistrian ruble",
      symbol: "р.",
      symbol_html: "&#x440;."
  },
  {
      code: "TTD",
      name: "Trinidad and Tobago dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "TND",
      name: "Tunisian dinar",
      symbol: "د.ت",
      symbol_html: "&#x62f;.&#x62a;"
  },
  {
      code: "TRY",
      name: "Turkish lira",
      symbol: "₺",
      symbol_html: "&#8378;"
  },
  {
      code: "TMT",
      name: "Turkmenistan manat",
      symbol: "m",
      symbol_html: "m"
  },
  {
      code: "UGX",
      name: "Ugandan shilling",
      symbol: "UGX",
      symbol_html: "UGX"
  },
  {
      code: "UAH",
      name: "Ukrainian hryvnia",
      symbol: "₴",
      symbol_html: "&#8372;"
  },
  {
      code: "AED",
      name: "United Arab Emirates dirham",
      symbol: "د.ا",
      symbol_html: "&#x62f;.&#x625;"
  },
  {
      code: "USD",
      name: "United States (US) dollar",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "UYU",
      name: "Uruguayan peso",
      symbol: "$",
      symbol_html: "&#36;"
  },
  {
      code: "UZS",
      name: "Uzbekistani som",
      symbol: "UZS",
      symbol_html: "UZS"
  },
  {
      code: "VUV",
      name: "Vanuatu vatu",
      symbol: "Vt",
      symbol_html: "Vt"
  },
  {
      code: "VES",
      name: "Venezuelan bolívar",
      symbol: "Bs.",
      symbol_html: "Bs."
  },
  {
      code: "VND",
      name: "Vietnamese đồng",
      symbol: "₫",
      symbol_html: "&#8363;"
  },
  {
      code: "XOF",
      name: "West African CFA franc",
      symbol: "CFA",
      symbol_html: "CFA"
  },
  {
      code: "YER",
      name: "Yemeni rial",
      symbol: "﷼",
      symbol_html: "&#xfdfc;"
  },
  {
      code: "ZMW",
      name: "Zambian kwacha",
      symbol: "ZK",
      symbol_html: "ZK"
  }
];
