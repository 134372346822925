<template>
    <div class="container-login">
        <div>
            <div class="card login-form">
                <div class="login-form__header">
                    <a href="https://magestic.app" target="_blank" title="Go to Magestic website">
                        <img class="logo" src="@/assets/img/logo.svg" alt="Magestic logo">
                    </a>
                </div>

                <h1 class="login-form__title">Sign in</h1>

                <div class="google-button__container">
                    <div class="google-button__wrapper">
                        <!-- <div class="google-button__custom" @click="handleGoogleLoginClick">Sign in with Google</div> -->
                        <GoogleLogin 
                            id="GoogleLogin" 
                            ref="googleLoginRef" 
                            :callback="handleGoogleLogin"
                            :buttonConfig="{ text: 'signin_with', width: '264', size: 'medium', locale: 'en' }"
                        />
                        <span class="loader-dots"><span></span><span></span><span></span></span>
                    </div>
                </div>
                
                <div class="login-form__divider">
                    <b></b>
                    <span>or</span>
                    <b></b>
                </div>

                <form>
                    <div class="login-form__content">
                        <div class="login-form__input-fields">
                            <div v-bind:class="{ 'error': isEmailValid == false }"
                                class="textfield textfield-large textfield-filled">
                                <div class="textfield-header">
                                    <label for="select">Email</label>
                                </div>
                                <input 
                                  type="text" 
                                  v-model.trim="email" 
                                  @blur="validateEmail()" 
                                  placeholder="john.doe@mail.com"
                                  @keydown.enter="handleSubmitClick"
                                />
                                <div v-if="isEmailValid == false" class="error-msg">Enter a valid email address</div>
                            </div>

                            <div v-bind:class="{ 'error': isPasswordValid == false }"
                                class="textfield textfield-large textfield-filled">
                                <div class="textfield-header">
                                    <label for="select">Password</label>
                                </div>
                                <div class="textfield__wrapper">
                                    <input 
                                      :type="isPasswordVisible ? 'text' : 'password'" 
                                      v-model.trim="password"
                                      @blur="validatePassword()"
                                      @keydown.enter.prevent="handleSubmitClick"
                                    />
                                    <button class="textfield__btn btn btn-toggle" @click.prevent="togglePasswordVisibility">
                                        <i :class="isPasswordVisible ? 'icon icon-eye-closed' : 'icon icon-eye-open'"></i>
                                    </button>
                                </div>
                                <div v-if="isPasswordValid == false" class="error-msg">Enter the password</div>
                            </div>

                        </div>

                        <div v-if="showAlert" class="alert alert-error">
                            <span class="alert-message">{{ apiErrorMessage }}</span>
                            <button @click="closeAlert()" type="submit" class="btn btn-toggle">
                                <i class="icon icon-close"></i>
                            </button>
                        </div>
                        
                        <div class="login-form__button">
                            <button 
                                class="btn btn-large btn-contained-primary g-recaptcha"
                                :class="{ 'btn-contained-primary--disabled': isLoading }"
                                @click.prevent="handleSubmitClick"
                                >
                                <span v-if="isLoading" class="button-loader"></span>
                                <span v-else>Sign in</span>
                            </button>
                        </div>
                    </div>
                </form>


                <div class="login-form__signup">
                    No account? <router-link to="/signup">Sign up</router-link>.
                </div>


                <div class="login-form__forgot-password">
                    Can't log in? <a href="mailto:support@magestic.app">Contact us</a>.
                </div>

                <div class="recaptcha-disclaimer">
                    This site is protected by reCAPTCHA and the Google 
                    <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and 
                    <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
                </div>

                <!-- Hidden container for reCAPTCHA widget -->
                <div id="recaptcha-container" style="display: none;"></div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authMixin from '@/mixins/authMixin';

export default {
  name: 'Login',
  mixins: [authMixin],
  methods: {
    handleRecaptchaSuccess() {
      this.loginUser('password');
    },

    validatePassword() {
      // This basic check can be overridden in the component if needed
      this.isPasswordValid = this.password.length > 0;
      return this.isPasswordValid;
    },

    async loginUser(authType) {
      this.isSubmitting = true;
      this.isLoading = true;
      this.showAlert = false;
      try {
        let payload = {};
        if (authType === 'google') {
          payload = {
            auth_type: 'google',
            id_token: this.idToken,
          };
        } else if (authType === 'password') {
          payload = {
            auth_type: 'password',
            username: this.email,
            password: this.password,
            recaptcha_token: this.recaptchaToken,
          };
        }
        const response = await axios.post('/login.php', payload, { timeout: 5000 });
        if (response.status === 200 && response.data.status === 'success') {
          this.$router.push({ name: 'Home' });
        }
      } catch (error) {
        if (error.response?.data?.status === 'error') {
          this.showAlert = true;
          this.apiErrorMessage = error.response.data.message || 'Login failed. Verify your username and password.';
        }
        if (window.grecaptcha) {
          this.recaptchaToken = null;
          setTimeout(() => window.grecaptcha.reset(), 500);
        }
      } finally {
        this.isSubmitting = false;
        this.isLoading = false;
      }
    },

    handleSubmitClick() {
      const isEmailValid = this.validateEmail();
      const isPasswordValid = this.validatePassword();
      if (!isEmailValid || !isPasswordValid) return;
      this.executeRecaptcha();
    },

    // If needed, you can also define a component-specific Google login handler
    handleGoogleLoginApi(authType) {
      this.loginUser(authType);
    },
  },
};
</script>