<template>
  <div class="container-login">
      <div>
          <div class="card login-form">
              <div class="login-form__header">
                  <a href="https://magestic.app" target="_blank" title="Go to Magestic website">
                      <img class="logo" src="@/assets/img/logo.svg" alt="Magestic logo">
                  </a>
              </div>

              <h1 class="login-form__title">Sign up</h1>

              <div class="google-button__container">
                  <div class="google-button__wrapper">
                      <GoogleLogin 
                          id="GoogleLogin" 
                          ref="googleLoginRef" 
                          :callback="handleGoogleSignup"
                          :buttonConfig="{ text: 'signup_with', width: '264', size: 'medium', locale: 'en' }"
                      />
                      <span class="loader-dots"><span></span><span></span><span></span></span>
                  </div>
              </div>

              <div class="login-form__divider">
                  <b></b>
                  <span>or</span>
                  <b></b>
              </div>

              <form>
                  <div class="login-form__content">
                      <div class="login-form__input-fields">
                          <div v-bind:class="{ 'error': isNameValid === false }" 
                               class="textfield textfield-large textfield-filled">
                              <div class="textfield-header">
                                  <label for="name">Full name</label>
                              </div>
                                <input 
                                  type="text" 
                                  v-model.trim="fullName" 
                                  @blur="validateName()" 
                                  placeholder="John Doe"
                                  @keydown.enter="handleSubmitClick"
                                />
                              <div v-if="isNameValid === false" class="error-msg">Enter a valid name</div>
                          </div>

                          <div v-bind:class="{ 'error': isEmailValid === false }" 
                               class="textfield textfield-large textfield-filled">
                              <div class="textfield-header">
                                  <label for="email">Email</label>
                              </div>
                              <input 
                                  type="text" 
                                  v-model.trim="email" 
                                  @blur="validateEmail()" 
                                  placeholder="john.doe@mail.com"
                                  @keydown.enter="handleSubmitClick"
                                />
                              <div v-if="isEmailValid === false" class="error-msg">Enter a valid email address</div>
                          </div>

                          <div v-bind:class="{ 'error': isPasswordValid === false }" 
                               class="textfield textfield-large textfield-filled">
                              <div class="textfield-header">
                                  <label for="password">Password</label>
                              </div>
                              <div class="textfield__wrapper">
                                  <input 
                                      :type="isPasswordVisible ? 'text' : 'password'" 
                                      v-model.trim="password"
                                      @blur="validatePassword()"
                                      @keydown.enter.prevent="handleSubmitClick"
                                    />
                                  <button class="textfield__btn btn btn-toggle" @click.prevent="togglePasswordVisibility">
                                      <i :class="isPasswordVisible ? 'icon icon-eye-closed' : 'icon icon-eye-open'"></i>
                                  </button>
                              </div>
                              <div v-if="isPasswordValid == false" class="error-msg">{{ passwordErrorMessage }}</div>
                          </div>
                      </div>

                      <div v-if="showAlert" class="alert alert-error">
                          <span class="alert-message">{{ apiErrorMessage }}</span>
                          <button @click="closeAlert()" type="button" class="btn btn-toggle">
                              <i class="icon icon-close"></i>
                          </button>
                      </div>

                      <div class="login-form__button">
                          <button 
                              class="btn btn-large btn-contained-primary"
                              :class="{ 'btn-contained-primary--disabled': isLoading }"
                              @click.prevent="handleSubmitClick"
                              >
                              <span v-if="isLoading" class="button-loader"></span>
                              <span v-else>Sign up</span>
                          </button>
                      </div>
                  </div>
              </form>

              <div class="login-form__signup login-form__signin">
                  Already a user? <router-link to="/login">Sign in</router-link>.
              </div>

              <div class="recaptcha-disclaimer">
                  This site is protected by reCAPTCHA and the Google 
                  <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and 
                  <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
              </div>

              <!-- Hidden container for reCAPTCHA widget -->
              <div id="recaptcha-container" style="display: none;"></div>
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import authMixin from '@/mixins/authMixin';

export default {
name: 'SignUp',
mixins: [authMixin],
watch: {
  password() {
    this.validatePassword();
  }
},
data() {
  return {
    fullName: '',
    isNameValid: undefined,
    idToken: null,  // Stores the Google ID token
  };
},
methods: {
  handleRecaptchaSuccess() {
    this.submitRegistration('password');
  },
  
  validateName() {
    this.isNameValid = this.fullName.length > 1;
    return this.isNameValid;
  },
  validatePassword() {
    if (!this.password || this.password.length === 0) {
      this.isPasswordValid = false;
      this.passwordErrorMessage = "Enter the password";
    } else if (this.password.includes('&')) {
      this.isPasswordValid = false;
      this.passwordErrorMessage = "Symbol '&' not allowed";
    } else if (this.password.length < 8) {
      this.isPasswordValid = false;
      this.passwordErrorMessage = "Must be at least 8 characters";
    } else {
      this.isPasswordValid = true;
      this.passwordErrorMessage = "";
    }

    return this.isPasswordValid;
  },
  async submitRegistration(authType) {
    this.isSubmitting = true;
    this.isLoading = true;
    this.showAlert = false;
    
    let payload = {};
    if (authType === 'google') {
      payload = {
        auth_type: 'google',
        id_token: this.idToken,
      };
    } else {
      if (!this.recaptchaToken) {
        this.showAlert = true;
        this.apiErrorMessage = 'reCAPTCHA token not found or invalid. Refresh and try again.';
        this.isLoading = false;
        return;
      }
      payload = {
        auth_type: 'password',
        contact_name: this.fullName,
        email: this.email,
        password: this.password,
        recaptcha_token: this.recaptchaToken,
      };
    }

    try {
      const response = await axios.post('/register.php', payload, { timeout: 5000 });
      if (response.status === 200 && response.data.status === 'success') {
        this.$router.push({ name: 'Home' });
      }
    } catch (error) {
      if (error.response?.data?.status === 'error') {
        this.showAlert = true;
        this.apiErrorMessage = error.response.data.message || 'Sign-up failed. Try again.';
      }
      if (window.grecaptcha) {
        this.recaptchaToken = null;
        setTimeout(() => window.grecaptcha.reset(this.widgetId), 500);
      }
    } finally {
      this.isSubmitting = false;
      this.isLoading = false;
    }
  },

  handleSubmitClick() {
    const isNameValid = this.validateName();
    const isEmailValid = this.validateEmail();
    const isPasswordValid = this.validatePassword();
    if (!isNameValid || !isEmailValid || !isPasswordValid) return;
    this.executeRecaptcha();
  },

  async handleGoogleSignup(response) {
    if (response.credential) {
      this.idToken = response.credential;
      await this.submitRegistration('google');
    } else {
      this.showAlert = true;
      this.apiErrorMessage = 'Google sign-up failed. Try again.';
    }
  }
}
};
</script>
