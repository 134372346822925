import { createStore } from 'vuex'
import axios from 'axios';
import { uiComponents } from '@/utils/globalVariables.js';

const store = createStore({
  state: {
    editMode: false,
    appPages: null,
    me: null,
    uiComponents: uiComponents,
    apiServices: null,
    userApiResources: [],
    isFetchingUserApiResources: {},
    isFetchingUserApiProperties: {},
    isFetchingUserUiElements: {},
    userApiResourcesFiltered: null,
    userApiProperties: null,
    userUiComponents: null,
    userUiElements: {}, 
    externalApiLookupData: {},
    defaultLocaleSettings: {}, // may not need this
    selectedPage: null,
    selectedUiComponentId: null,
    selectedUiComponent: null, // may not need this
    selectedUiElement: null,
    selectedApiService: null,
    selectedUserApiResource: null,
    refreshExternalDataInComponent: {},
    isDeletingExternalApiEntry: {},
    tableRowBindingView: [],
    showContextMenu: false,
    dropdowns: {},
    notifications: [],
    showModalDeletePage: false,
    showModalDeleteUserUiComponent: false,
    showModalDeleteUserUiElement: false,
    showModalDeleteExternalApiEntry: false,
    showModalAddRenamePage: false,
    showModalPropertyDetails: {},
    showModalAddUserIntegration: false,
    modalComponentData: null,
    formData: {},
    modalTargetPageId: null,
    keyListenerComponentId: null,
    navWidth: 200,
    tableSelectSelectedValues: {},
    multiselectDropdownOpen: null,
    groupElementValueIndexes: {},
    modalStack: []
  },
  mutations: {
    setEditMode (state, value) {
      state.editMode = value;
    },
    updateAppPages(state, pages) {
      state.appPages = pages;
    },
    setMe(state, me) {
      state.me = me;
    },
    setSelectedPage(state, page) {
      state.selectedPage = page;
      // console.log('setSelectedPage.id', page.id)
      // history.pushState({}, '', 'pages/' + page.slug);
    },
    setSelectedUiComponentId(state, id) {
      state.selectedUiComponentId = id;
    },
    setSelectedUiComponent(state, payload) {
      state.selectedUiComponent = payload;
    },
    setSelectedUiElement(state, payload) {
      // console.log('setSelectedUiElement', payload)
      state.selectedUiElement = payload;
    },
    setUserUiComponents(state, userUiComponents) {
      state.userUiComponents = userUiComponents;
    },
    setUserUiElements(state, { componentId, data }) {
      state.userUiElements[componentId] = data;
    },
    removeUserUiElements(state, componentId) {
      delete state.userUiElements[componentId];
    },
    setMultiselectDropdownOpen(state, id) {
      state.multiselectDropdownOpen = id;
    },
    addToModalStack(state, modalId) {
      state.modalStack.push(modalId);
    },
    removeFromModalStack(state) {
      state.modalStack.pop();
    },
    setExternalApiLookupData(state, { element, data }) {
      const elementId = element.id;
      if (!state.externalApiLookupData[elementId]) {
        state.externalApiLookupData[elementId] = [];
      }
      // console.log('⭕ setExternalApiLookupData 0: elementId', elementId, 'data', data, 'state.externalApiLookupData[elementId]', state.externalApiLookupData[elementId])
      const keyProperty = element.validation_rule?.key_property;
      // const keyPropertyValue = Array.isArray(data) && data.length === 1 ? data[0]?.[keyProperty] : data?.[keyProperty];
      const keyPropertyValue = data?.[keyProperty]
      // console.log('⭕ setExternalApiLookupData 0-1: elementId', elementId, 'data', data, 'keyProperty', keyProperty, 'keyPropertyValue', keyPropertyValue)
      if (typeof data === 'object' && keyProperty && keyPropertyValue) {
        const index = state.externalApiLookupData[elementId].findIndex(item => item[keyProperty] === keyPropertyValue);
        if (index !== -1) {
          // console.log('⭕ setExternalApiLookupData 1: elementId', elementId, 'data', data, 'state.externalApiLookupData[elementId]', state.externalApiLookupData[elementId])
          state.externalApiLookupData[elementId][index] = data;
        } else {
          // console.log('⭕ setExternalApiLookupData 2: elementId', elementId, 'data', data, 'state.externalApiLookupData[elementId]', state.externalApiLookupData[elementId])
          state.externalApiLookupData[elementId].push(data);
        }
        return;
      }

      // if(element?.validation_rule?.multi_page_preload || element?.validation_rule?.is_sequential_requests) {
      //   state.externalApiLookupData[elementId] = state.externalApiLookupData[elementId].concat(data); 
      //   // Check if data is of type object and has a key_property
      //   console.trace('⭕ setExternalApiLookupData 3: elementId', elementId, 'data', data?.length, 'state.externalApiLookupData[elementId]', state.externalApiLookupData[elementId]?.length)
      // } 

      // This workaround is to handle the case where the data is an array of objects and the key_property is not defined in the validation_rule
      if (keyProperty && (element?.validation_rule?.multi_page_preload || element?.validation_rule?.is_sequential_requests)) {
        const existingData = state.externalApiLookupData[elementId] || [];
        const existingIds = new Set(existingData.map(item => item[keyProperty]));
      
        const newData = data.filter(item => !existingIds.has(item[keyProperty]));
        state.externalApiLookupData[elementId] = existingData.concat(newData);
        // console.log('⭕ setExternalApiLookupData 3: elementId', elementId, 'data', data?.length, 'state.externalApiLookupData[elementId]', state.externalApiLookupData[elementId]?.length);
      }
      else if (Array.isArray(data)) {
        state.externalApiLookupData[elementId] = data;
        // console.log('⭕ setExternalApiLookupData 4: elementId', elementId, 'data', data?.length, 'state.externalApiLookupData[elementId]', state.externalApiLookupData[elementId])
      }
    },
    setTableRowBindingView(state, { sourceComponentId, parentItemVariables, operation, linkedElementData }) {
      const index = state.tableRowBindingView.findIndex(binding => binding.sourceComponentId == sourceComponentId);
      if (index !== -1) {
        state.tableRowBindingView[index].linkedElementData = linkedElementData;
        state.tableRowBindingView[index].operation = operation;
        state.tableRowBindingView[index].sourceComponentId = sourceComponentId;
        state.tableRowBindingView[index].parentItemVariables = parentItemVariables;
      } else {
        state.tableRowBindingView.push({ sourceComponentId, parentItemVariables, operation, linkedElementData });
      }
    },
    removeTableRowBindingView(state, sourceComponentId) {
      const index = state.tableRowBindingView.findIndex(binding => binding.sourceComponentId == sourceComponentId);
      if (index !== -1) {
        state.tableRowBindingView.splice(index, 1);
      }
    },
    resetTableRowBindingView(state) {
      state.tableRowBindingView = [];
    },
    setSelectedApiService(state, payload) {
      state.selectedApiService = payload;
    },
    setSelectedUserApiResource(state, payload) {
      state.selectedUserApiResource = payload;
    },
    showContextMenu (state, payload) {
      state.showContextMenu = payload
    },
    showModalDeletePage (state, pageId) {
      state.showModalDeletePage = pageId
    },
    showModalDeleteUserUiComponent (state, id) {
      state.showModalDeleteUserUiComponent = id
    },
    showModalDeleteUserUiElement (state, id) {
      state.showModalDeleteUserUiElement = id
    },
    showModalDeleteExternalApiEntry (state, id) {
      state.showModalDeleteExternalApiEntry = id
    },
    showModalConfirmDelete(state, { componentId, id }) {
      state.isDeletingExternalApiEntry[componentId] = id;
    },
    showModalAddRenamePage (state, pageId) {
      state.showModalAddRenamePage = pageId
    },
    showModalAddUserIntegration (state) {
      state.showModalAddUserIntegration = state
    },
    setIsFetchingUserApiProperties(state, { componentId, resourceId, value }) {
      state.isFetchingUserApiProperties = {
        ...state.isFetchingUserApiProperties,
        [componentId]: {
          ...state.isFetchingUserApiProperties[componentId],
          [resourceId]: value
        }
      }
    },
    setShowModalPropertyDetails(state, { elementId, stateValue }) {
      if (stateValue === false) {
        delete state.showModalPropertyDetails[elementId];
      } else {
        state.showModalPropertyDetails = {
          ...state.showModalPropertyDetails,
          [elementId]: stateValue
        };
      }
    },
    setGroupElementValueIndex(state, { elementId, groupElementValueIndex }) {
      state.groupElementValueIndexes = {
        ...state.groupElementValueIndexes,
        [elementId]: groupElementValueIndex
      }
    },
    initializeGroupElementValueIndexes(state, { elementId, groupElementValueIndexesCount }) {
      state.groupElementValueIndexes[elementId] = groupElementValueIndexesCount;
    },
    setModalComponentData(state, component) {
      state.modalComponentData = component
    },
    setDropdownState(state, { id, value }) {
      state.dropdowns = {
        ...state.dropdowns,
        [id]: value
      }
    },
    changeTableSelectValues(state, { sourceElementId, values, groupElementValueIndex }) {
      // if(sourceElementId === 9588) {
      //   console.trace('⭕ changeTableSelectValues: sourceElementId', sourceElementId, 'values', values);
      // }
      // console.trace('⭕ changeTableSelectValues: sourceElementId', sourceElementId, 'values', values);

      const resolvedGroupElementValueIndex = groupElementValueIndex ?? state.groupElementValueIndexes[sourceElementId];
      // console.log('🔴 changeTableSelectValues: sourceElementId', sourceElementId, 'resolvedGroupElementValueIndex', resolvedGroupElementValueIndex, 'values', values);
      if (resolvedGroupElementValueIndex !== undefined) {
        // If resolvedGroupElementValueIndex is defined, deal with nested structure
        if (!state.tableSelectSelectedValues[sourceElementId]) {
          // console.log('🔴 changeTableSelectValues: sourceElementId', sourceElementId, 'resolvedGroupElementValueIndex', resolvedGroupElementValueIndex, 'does not exist. Creating new structure');
          state.tableSelectSelectedValues[sourceElementId] = {};
        }
        // Initialize as an empty array if resolvedGroupElementValueIndex is defined
        if (!state.tableSelectSelectedValues[sourceElementId][resolvedGroupElementValueIndex]) {
          state.tableSelectSelectedValues[sourceElementId][resolvedGroupElementValueIndex] = [];
        }
        // Replace the array with the new values
        state.tableSelectSelectedValues[sourceElementId][resolvedGroupElementValueIndex] = values;
        // console.log('🔴 Nested Replace: changed values', values, 'at index', resolvedGroupElementValueIndex, 'for sourceElementId', sourceElementId);
      } else {
        // Handle the flat array case
        if (!state.tableSelectSelectedValues[sourceElementId]) {
          // console.log('🔴 changeTableSelectValues: sourceElementId', sourceElementId, 'does not exist. Creating new structure');
          state.tableSelectSelectedValues[sourceElementId] = [];
        }
        // Replace the array with the new values
        state.tableSelectSelectedValues[sourceElementId] = values;
        // console.log('🔴 Flat Replace: changed values', values, 'for sourceElementId', sourceElementId);
      }
    },
    addTableSelectValues(state, { sourceElementId, value, groupElementValueIndex }) {
      // this.setGroupElementValueIndex(state, { elementId: sourceElementId, resolvedGroupElementValueIndex });
      // if(sourceElementId === 9588) {
        // console.log('⭕ addTableSelectValues: sourceElementId', sourceElementId, 'value', value)
      // }

      const resolvedGroupElementValueIndex = groupElementValueIndex ?? state.groupElementValueIndexes[sourceElementId];
      // console.log('⭕ addTableSelectValues: sourceElementId', sourceElementId, 'resolvedGroupElementValueIndex', resolvedGroupElementValueIndex, 'tableSelectSelectedValues', state.tableSelectSelectedValues, 'value', value)

      // Check if the sourceElementId exists in the state
      if (!state.tableSelectSelectedValues[sourceElementId]) {
        // console.log('⭕ addTableSelectValues: sourceElementId', sourceElementId, 'resolvedGroupElementValueIndex', resolvedGroupElementValueIndex, 'does not exist. Creating new structure. state.tableSelectSelectedValues[sourceElementId]', state.tableSelectSelectedValues[sourceElementId]);
        // Initialize as an empty object if resolvedGroupElementValueIndex is defined, otherwise an array
        state.tableSelectSelectedValues[sourceElementId] = resolvedGroupElementValueIndex !== undefined ? {} : [];
        // console.log('⭕ addTableSelectValues: sourceElementId', sourceElementId, 'state.tableSelectSelectedValues[sourceElementId]', state.tableSelectSelectedValues[sourceElementId]);
      }
    
      if (resolvedGroupElementValueIndex !== undefined) {
        // If resolvedGroupElementValueIndex is defined, deal with nested structure
        if (!state.tableSelectSelectedValues[sourceElementId][resolvedGroupElementValueIndex]) {
          state.tableSelectSelectedValues[sourceElementId][resolvedGroupElementValueIndex] = [];
        }
        // Handling adding/updating value in nested array
        const nestedArray = state.tableSelectSelectedValues[sourceElementId][resolvedGroupElementValueIndex];
        // console.log('⭕ Nested: nestedArray in sourceElementId', sourceElementId, 'nestedArray', nestedArray, 'tableSelectSelectedValues', state.tableSelectSelectedValues, 'value', value)
        const index = nestedArray.findIndex(item => item.key === value.key);
        if (index !== -1) {
          // Replace the old value
          nestedArray.splice(index, 1, value);
          // console.log('⭕ Nested Replace: added value', value, 'at index', resolvedGroupElementValueIndex, 'for sourceElementId', sourceElementId, 'tableSelectSelectedValues', state.tableSelectSelectedValues);
        } else {
          // Add new value
          nestedArray.push(value);
          // console.log('⭕ Nested Add: added value', value, 'at index', resolvedGroupElementValueIndex, 'for sourceElementId', sourceElementId, 'tableSelectSelectedValues', state.tableSelectSelectedValues);
        }
      } else {
        // Handle the flat array case
        const flatArray = state.tableSelectSelectedValues[sourceElementId];
        // console.log('⭕ Flat: flatArray', flatArray, 'in sourceElementId', sourceElementId, 'value', value)
        const index = flatArray.findIndex(item => item.key === value.key);
        if (index !== -1) {
          // Replace the old value
          flatArray.splice(index, 1, value);
          // console.log('⭕ Flat Replace: replaced value', value, 'in sourceElementId', sourceElementId);
        } else {
          // Add new value
          flatArray.push(value);
          // console.log('⭕ Flat Add: added value', value, 'to sourceElementId', sourceElementId);
        }
      }
    },    
    removeTableSelectValues(state, { sourceElementId, value }) {
      const groupElementValueIndex = state.groupElementValueIndexes[sourceElementId];

      // Check if the sourceElementId exists in the state
      if (state.tableSelectSelectedValues[sourceElementId]) {
        if (groupElementValueIndex !== undefined) {
          // Handle the nested case
          const nestedArray = state.tableSelectSelectedValues[sourceElementId][groupElementValueIndex];
          if (nestedArray) {
            const index = nestedArray.findIndex(item => item.key === value.key);
            if (index !== -1) {
              // Remove the value from the nested array
              // console.log('⭕ Nested remove: removed value', value, 'from', groupElementValueIndex, 'of sourceElementId', sourceElementId);
              nestedArray.splice(index, 1);
            }
          }
        } else {
          // Handle the flat array case
          const flatArray = state.tableSelectSelectedValues[sourceElementId];
          const index = flatArray.findIndex(item => item.key === value.key);
          if (index !== -1) {
            // Remove the value from the flat array
            // console.log('⭕ Flat remove: removed value', value, 'from sourceElementId', sourceElementId);
            flatArray.splice(index, 1);
          }
        }
      }
    },    
    clearTableSelectValues(state, { sourceElementId, groupElementValueIndex, reindex = false }) {
      if (state.tableSelectSelectedValues[sourceElementId]) {
        if (groupElementValueIndex !== undefined) {
          // Use Object.prototype.hasOwnProperty.call to check the property safely
          if (Object.prototype.hasOwnProperty.call(state.tableSelectSelectedValues[sourceElementId], groupElementValueIndex)) {
            // Remove the specified index
            delete state.tableSelectSelectedValues[sourceElementId][groupElementValueIndex];
    
            if (reindex) {
              // Object keys are returned as string, need to convert them to numbers and sort
              const indices = Object.keys(state.tableSelectSelectedValues[sourceElementId])
                .map(Number)
                .filter(index => index > groupElementValueIndex)
                .sort((a, b) => a - b);
    
              // Shift all indices down to fill the gap
              indices.forEach(currentIndex => {
                const newIndex = currentIndex - 1;
                state.tableSelectSelectedValues[sourceElementId][newIndex] = state.tableSelectSelectedValues[sourceElementId][currentIndex];
                delete state.tableSelectSelectedValues[sourceElementId][currentIndex];
              });
            }
          }
        } else {
          // Clear all values associated with the sourceElementId
          delete state.tableSelectSelectedValues[sourceElementId];
        }
      }
    },     
    clearFullTableSelectValues(state) {
      state.tableSelectSelectedValues = {};
  },
    addNotification(state, { componentId, type, title, message }) {
      const id = state.notifications.length + 1;
      state.notifications.push({
        id: state.notifications.length + 1,
        componentId,
        type,
        title,
        message,
      });
    
      if (type === 'success') {
        setTimeout(() => {
          this.commit('removeNotification', id);
        }, 5000);
      }
    },
    removeNotification(state, id) {
      state.notifications = state.notifications.filter(notification => notification.id !== id);
    },
    setApiServices(state, payload) {
      state.apiServices = payload;
    },
    setUserApiResources(state, payload) {
      state.userApiResources = [...state.userApiResources, ...payload];
    },
    setUserApiResourcesFiltered(state, payload) {
      state.userApiResourcesFiltered = payload;
    },
    setUserApiProperties(state, payload) {
      state.userApiProperties = payload;
    },
    setFormData(state, payload) {
      state.formData = payload;
    },
    modalTargetPageId (state, id) {
      state.modalTargetPageId = id
    },
    setKeyListenerComponentId (state, id) {
      state.keyListenerComponentId = id
    },
    setNavWidth (state, width) {
      state.navWidth = width
    },
    requestRefreshExternalDataInComponent(state, { componentId, value }) {
      // state.refreshExternalDataInComponent = componentId
      state.refreshExternalDataInComponent = {
        ...state.refreshExternalDataInComponent,
        [componentId]: value
      }
    },
    setIsDeletingExternalApiEntry(state, { componentId, value }) {
      // state.refreshExternalDataInComponent = componentId
      state.isDeletingExternalApiEntry = {
        ...state.isDeletingExternalApiEntry,
        [componentId]: value
      }
    },
    setIsFetchingUserApiResources(state, { connectionId, value }) {
      state.isFetchingUserApiResources = {
        ...state.isFetchingUserApiResources,
        [connectionId]: value
      }
    },
    setIsFetchingUserUiElements(state, { componentId, value }) {
      state.isFetchingUserUiElements = {
        ...state.isFetchingUserUiElements,
        [componentId]: value
      }
    },
  },
  actions: {
      clearFormNewValuesTableSelect({ commit, state }, componentId) {
        if (!componentId) return;
        
        const elementsInComponent = state.userUiElements[componentId];
        if (!elementsInComponent) return;
    
        const tableSelectElements = elementsInComponent.filter(element => element.operation === 'table-select');
        if (!tableSelectElements.length) return;
    
        tableSelectElements.forEach(element => {
          commit('clearTableSelectValues', { sourceElementId: element.id });
        });
      },   
    async fetchPages({ commit, state }) {
      await axios.get('/pages.php')
        .then(response => {
          // console.log('Pages list', response.data);
          commit('updateAppPages', response.data);
          if (response.data.length > 0) {
            // check if selectedPage is null or does not exist then set it to the first page
            if (state.selectedPage === null || !state.appPages.find(p => p.id === state.selectedPage.id)) {
              commit('setSelectedPage', response.data[0]);
            }
          }
          
        })
        .catch(error => {
          console.error(error.response);
        });
    },
    async fetchUser({ commit }) {
      try {
        const response = await axios.get('/me.php');
        // fetch pages
        commit('setMe', response.data);
      } catch (error) {
        console.error(error.response);
      }
    },    
    async loginAsUser({ dispatch, commit }, userId) {
      try {
        // Clear current API services state before switching user
        commit('setApiServices', null);
        commit('setSelectedApiService', null);
        
        await axios.get('/login_as.php', {
          params: {
            id: userId
          }
        });
        
        // Fetch fresh data for the new user
        await dispatch('fetchUser');
        await dispatch('fetchPages');
        await dispatch('fetchApiServices');
      } catch (error) {
        console.error(error.response);
      }
    },  
    async fetchApiServices({ commit }) {
      try {
        const response = await axios.get('/api_services.php'); // Adjust the endpoint if needed
        commit('setApiServices', response.data);
      } catch (error) {
        console.error('Error fetching API services:', error.response?.data || error);
      }
    },      
    // Extract userName from token
    async fetchUserUiComponents({ commit, state }) {
      // console.log('requesting UI components for page ' + state.selectedPage.id + '...')
      const selectedPageId = state.selectedPage ? state.selectedPage.id : null;
      await axios.get('/components.php?page_id=' + parseInt(selectedPageId))
        .then(async response => {
          if (response.data.length > 0) {
            // console.log('userUiComponents', response.data);
            commit('setUserUiComponents', response.data);
            // await dispatch('fetchUserUiElements');
          } else {
            commit('setUserUiComponents', []);
          }
        })
        .catch(error => {
          console.error(error.response.data.message);
        });
    },
    async getUiComponentData({ commit, state }) {
      console.log('requesting UI component data');
      await axios.get('/components.php?id=' + state.selectedUiComponentId)
      .then(response => {
          if (response.status === 200) {
              console.log('selectedUiComponent', response.data);
              commit('selectedUiComponent', response.data);
              }
      })
      .catch(error => {
          if (error.response) {
              console.error(error.response.data);
          } else {
              console.error(error);
          }
      })
  },
    async deleteUserUiComponent({ commit, state, dispatch }) {
      if(state.selectedUiComponentId === null) {
        return;
      }
      // console.log('requesting deletion of UI component with id ' + state.selectedUiComponentId + '...');
        // Find the index of the item with the selectedUiComponentId
      const component = state.userUiComponents.find(component => component.id === state.selectedUiComponentId);
      const index = state.userUiComponents.findIndex(component => component.id === state.selectedUiComponentId);

      if (index > -1) {
        // Remove the item from the array
        state.userUiComponents.splice(index, 1);
      }
      await axios.delete('/components.php?id=' + state.selectedUiComponentId)
        .then(response => {
          if (response.status === 204) {
            // console.log('UI component deleted');
            // console.log(response);
            commit('setSelectedUiComponent', null);
            commit('setSelectedUiComponentId', null);
            dispatch('fetchUserUiComponents');
            commit('showModalDeleteUserUiComponent', null);
            commit('addNotification', { 
              componentId: component.id,
              type: 'success',
              title: `Component "${component.title}" deleted`, 
          });
          } 
        })
        .catch(error => {
          console.error(error.response.data.message);
          // const message = error.response.data?.message || error.response.data;
          commit('addNotification', { 
            componentId: component.id,
            type: 'error',
            title: 'Component deletion failed'
            // message: message
        });
        });
    },
    async addUiComponentToPage({state, dispatch, getters}, componentIndex) {
      const component = state.uiComponents[componentIndex];
      if (!component) {
        console.error('Component not found');
        return;
      }

      try {
        const response = await axios.post('/components.php', {
          page_id: getters.selectedPageId,
          title: component.name,
          type: component.type
        }
        );

        if (response.status === 201) {
          await dispatch('fetchUserUiComponents')
        } else {
          console.error('Response status is not 201');
        }

      } catch (error) {
        // this.apiErrorMessage = error.response
        console.error(error.response)
      }
    },
    async fetchUserApiResources({ commit, state }, { connectionId }) {
      // Check if userApiResources has at least one item with the provided connection_id. This means that the resource with this connectionId have already been fetched.
      const hasConnectionId = state.userApiResources?.some(resource => resource.connection_id === connectionId);

      if(hasConnectionId || state.isFetchingUserApiResources[connectionId]) return;

      try {
        // console.log('Requesting user API resources for connection_id:', connectionId)
          commit('setIsFetchingUserApiResources', {connectionId, value: true});
          const response = await axios.get('/resources.php', {
              params: {
                  connection_id: connectionId
              }
          });
          if (response.status === 200 && connectionId === response.data[0].connection_id) {
              commit('setUserApiResources', response.data)
              commit('setIsFetchingUserApiResources', {connectionId, value: false});
          }
      } catch (error) {
          if (error.response) {
              console.error(error.response.data)
          }
          // else {
          //     console.error(error)
          // }
      }
    },
    async fetchUserUiElements({ commit }, { componentId }) {
      // console.log('Requesting UI elements for component_id:', componentId)
      try {
        commit('setIsFetchingUserUiElements', { componentId, value: true });
        const response = await axios.get('/elements.php?component_id=' + componentId);
        if (response.data.length > 0) {
          const data = response.data;
          commit('setUserUiElements', { componentId, data });
        } else {
          commit('setUserUiElements', []);
        }
      } catch (error) {
        console.error('An error occurred while fetching UI elements:', error);
        commit('setUserUiElements', []);
      } finally {
        commit('setIsFetchingUserUiElements', { componentId, value: false });
      }
    },    
    async deleteUserUiElement({ commit, state, dispatch }) {
      if(state.selectedUiElement === null) {
        return;
      }

      const elementName = state.selectedUiElement.display_name;
      const elementOperation = state.selectedUiElement.operation;
      const elementParentId = state.selectedUiElement.parent_id;
      
      // Find all elements with the specified parent_id
      const elementsWithSameParent = state.userUiElements[state.selectedUiComponentId].filter(element => element.parent_id === elementParentId);
      
      if (elementsWithSameParent.length === 1) {
        // Remove the parent element if there's only one such element
        const parentIndex = state.userUiElements[state.selectedUiComponentId].findIndex(element => element.id === elementParentId);
        if (parentIndex > -1) {
          state.userUiElements[state.selectedUiComponentId].splice(parentIndex, 1);
        }
      }
      
      // Find the index of the selected item
      const index = state.userUiElements[state.selectedUiComponentId].findIndex(element => element.id === state.selectedUiElement.id);
      
      if (index > -1) {
        // Remove the selected item from the array
        state.userUiElements[state.selectedUiComponentId].splice(index, 1);
      }
      
      await axios.delete('/elements.php?id=' + state.selectedUiElement.id)
        .then(response => {
          if (response.status === 204) {
            // console.log('UI Element deleted');
            // console.log(response);
            if (index === 0) {
              // console.log('before removal', state.userUiElements)
              commit('removeUserUiElements', state.selectedUiComponentId);
              // console.log('removed component', state.userUiElements)
            }
            commit('setSelectedUiElement', null);
            // dispatch('fetchUserUiElements');
            dispatch('fetchUserUiElements', { componentId: state.selectedUiComponentId });
            commit('showModalDeleteUserUiElement', null);
            commit('addNotification', { 
              componentId: state.selectedUiComponentId,
              type: 'success',
              title: `Element "${elementName}"${elementOperation === 'group' ? ' and all its child elements' : ''} removed`, 
          });

          } 
        })
        .catch(error => {
          console.error(error.response.data.message);
          // const message = error.response.data?.message || error.response.data;
          commit('addNotification', { 
            componentId: state.selectedUiComponentId,
            type: 'error',
            title: 'Element deletion failed' 
            // message: message
        });
        });
      },
      async updateUiElement({ state, dispatch }, {position, userDisplayFormat = null }) {
        try {
            const response = await axios.put('/elements.php', {
                id: state.selectedUiElement?.id,
                position: position || state.selectedUiElement?.position,
                user_display_format: userDisplayFormat
            });
            if (response.status === 200) {
                // console.log(response);
                await dispatch('fetchUserUiElements', { componentId: state.selectedUiComponentId });
            } else {
                throw new Error('Error: response status is not 200');
            }
        } catch (error) {
            console.log(error.response);
            throw error;
        }
      },
      async addUserUiElementToCard({ state, dispatch, commit }, properties) {
        if (!properties || properties.length === 0) {
            console.error('Properties are undefined or empty');
            return;
        }
    
        // Validate each property
        for (const property of properties) {
            if (property.resource_id !== state.selectedUserApiResource?.id) {
                commit('addNotification', { 
                    componentId: state.selectedUiComponentId,
                    type: 'error',
                    title: 'Incompatible property',
                    message: `Property <b>${property.display_name}</b> is not compatible with <b>${state.selectedUserApiResource.name}</b> component. Select same-resource property.`
                });
                console.error(`Dropped element resource_id=${property.resource_id} not matching selected component resource.id=${state.selectedUserApiResource?.id}`);
                return;
            }
        }
    
        // Extract property_ids from the properties
        const property_ids = properties.map(property => property.id);
    
        await axios.post('/elements.php', {
            connection_id: state.selectedApiService?.connection_id,
            component_id: state.selectedUiComponentId,
            resource_id: state.selectedUserApiResource?.id,
            property_id: property_ids // Send property_id as an array
        })
        .then(response => {
            if (response.status === 201) {
                dispatch('fetchUserUiElements', { componentId: state.selectedUiComponentId });
            } else {
                throw new Error('Error: response status is not 200');
            }
        })
        .catch(error => {
            console.error(error);
        });
    }    
  },
  getters: {
    selectedPageId: state => {
      return state.selectedPage ? state.selectedPage.id : null;
    },
    
  }
})

export default store
